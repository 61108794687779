<template>
  <div id="film-show" :key="path">
    <Header :backgroundColor="menuColor" />
    <div class="film-container">
      <div class="film-title">
        {{ title }}
      </div>
      <div class="item-container">
        <el-row>
          <el-col :span="12" v-for="item in filmList" :key="item.Id">
            <div class="movie">
              <div
                class="movie-item"
                :style="'background-image:url(' + item.ImgUrl + ')'"
                @click="gotoDetails(item.Id)"
              />
              <p>{{ item.Name }}</p>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
  import Header from "./components/Header.vue";
  import Footer from "./components/Footer.vue";
  import { getFilmList } from "@/network/index";
  export default {
    name: "FilmShow",
    components: { Header, Footer },
    data() {
      return {
        path: this.$route.path,
        menuColor: "rgb(3, 105, 179)",
        filmList: [],
        shortFilmList: [],
        showList: {
          isAll: false,
          txt: "查看更多",
          icon: "divider-icon divider-icon-down",
          list: [],
        },
      };
    },
    created() {
      this.init();
    },
    // beforeRouteUpdate(to, from, next) {
    //   if (to.fullPath != from.fullPath) {
    //     next();
    //     this.init();
    //   }
    // },
    watch: {
      $route(to, from) {
        if (to.fullPath != from.fullPath) {
          // console.log(to.path);
          this.path = to.path;
          this.init();
        }
      },
    },
    computed: {
      title() {
        if (this.path == "/zy/wangjie") return "往届电影展映";
        if (this.path == "/zy/benjie") return "中外电影展映";
        if (this.path == "/zy/show") return "中外影片展映";
        return "";
        // return "多国别电影展映";
      },
    },

    methods: {
      init() {
        this.type = this.$route.params.type;
        this.getFilmList();
      },

      getFilmList() {
        let type = "";
        console.log(this.path);
        switch (this.path) {
          case "/zy/benjie":
            type = "bj";
            break;
          case "/zy/wangjie":
            type = "wj";
            break;
        }
        let data = { category: type };
        getFilmList(data).then((res) => {
          let result = res.result;
          // 图片和名字处理
          result.forEach((item) => {
            if (item.ImgUrl.length > 0) {
              item.ImgUrl = item.ImgUrl[0].url;
            } else {
              item.ImgUrl = "";
            }
            if (item.Name != "") item.Name = "《" + item.Name + "》";
          });
          this.filmList = result;
          console.log("filmList", this.filmList);
        });
      },
      gotoDetails(id) {
        this.$router.push({
          path: "/zy/mvdetail",
          query: {
            id,
          },
        });
      },
    },
  };
</script>
<style scoped>
  .film-container {
    position: relative;
    padding-top: 50px !important;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow-y: auto;
    background-color: #fff;
  }
  .item-container {
    position: relative;
    width: 100%;
    padding: 20px 8px 0 8px;
    box-sizing: border-box;
  }
  .film-title {
    position: relative;
    width: 100%;
    font-size: 24px;
    box-sizing: border-box;
    padding: 22px 16px 22px 16px;
    font-weight: bold;
    color: #fff;
    background-image: url("../assets/images/index/bg1.png");
    background-position: top;
    background-repeat: no-repeat;
    font-family: "zt";
    letter-spacing: 1px;
    /* text-align: center; */
  }
  .movie {
    padding: 0 8px;
    display: inline-block;
    width: 100%;
    box-sizing: border-box;
  }
  .movie-item {
    width: 100%;
    height: 0;
    padding-bottom: 149.83%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .movie p {
    position: relative;
    width: 100%;
    height: 30px;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
    letter-spacing: 1px;
    bottom: 30px;
    text-align: center;
    text-overflow: ellipsis;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    background-image: linear-gradient(rgba(29, 17, 31, 0), #1d111f);
  }
</style>
